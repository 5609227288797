hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee
}

.h1,
.h2,
.h3 {
    margin-top: 20px;
    margin-bottom: 10px
}

.h1 {
    font-size: 36px
}

.h2{
    font-size: 30px
}

.h4 {
    font-size: 18px
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 16px;
    padding-right: 16px
}

@media (min-width:768px) {
    .container {
        width: 720px;
        padding-left: 24px;
        padding-right: 24px
    }
}

@media (min-width:992px) {
    .container {
        width: 944px
    }
}

@media (min-width:1300px) {
    .container {
        width: 1252px
    }
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 16px;
    padding-right: 16px
}

@media (min-width:768px) {
    .container-fluid {
        padding-left: 24px;
        padding-right: 24px
    }
}

.row {
    margin-left: -8px;
    margin-right: -8px
}

@media (min-width:768px) {
    .row {
        margin-left: -8px;
        margin-right: -8px
    }
}

@media (min-width:992px) {
    .col-md-9 {
        width: 75%
    }
}

@media (min-width:1200px) {
    .col-lg-8 {
        width: 66.66666667%
    }

    .col-lg-4 {
        width: 33.33333333%
    }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block
}

*,
:after,
:before {
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

a {
    text-decoration: none
}

img {
    vertical-align: middle;
    border: 0
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6{
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit
}

.h4,
.h5,
.h6{
    margin-top: 10px;
    margin-bottom: 10px
}

ol,
ul {
    margin-top: 0;
    margin-bottom: 10px
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 8px;
    padding-left: 8px
}

@media (min-width:768px) {

    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9 {
        float: left
    }

    .col-sm-12 {
        width: 100%
    }
}

@media (min-width:992px) {

    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9 {
        float: left
    }

    .col-md-6 {
        width: 50%
    }

    .col-md-3 {
        width: 25%
    }
}

@media (min-width:1200px) {

    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9 {
        float: left
    }
}

.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.row:after,
.row:before {
    display: table;
    content: " "
}

.clearfix:after,
.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.row:after {
    clear: both
} 

p {
    margin: 0
}

img {
    height: auto;
    max-width: 100%;
}

a,
a:active,
a:hover,
a:link {
    text-decoration: none;
    justify-content: space-between;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

body {
    max-width: 100%;
    margin: 0
}

